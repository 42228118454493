<template>
    <div class="work-map">
        <div id="container"></div> 
        <div class="bom-box">
            <div class="title-box">本次出差行程</div>
            <div class="bom-list-box" v-show="!showLoading">
                <div class="bom-list-main" v-show="!showKong">
                    <div class="bom-lis" v-for="(item,index) in mapList" :key="item.id" @click="clickLook(item)">
                        <div class="top-lis-son">
                            <div class="lis-s2">{{index+1}}</div>
                            <div class="lis-s1">
                                <img src="../../assets/imgs/img-dianwei.png" alt="" class="img-dianwei">
                            </div>
                            <div class="lis-s3"><span>{{item.userAddress}}</span></div>
                        </div>
                        <div class="bom-lis-son">{{item.clockDetails}}</div>

                        <div class="img-box-daka" v-show="item.photoUrl !== null && item.photoUrl !== ''">
                            <img :src="item.photoUrl" alt="">
                        </div>
                    </div>
                </div>
                <div class="kong-box" v-show="showKong">
                    <img src="../../assets/imgs/img-no-data.png" alt="">
                    <p>暂无打卡数据 ~</p>
                </div>
            </div>
            <div class="loadin-box" v-show="showLoading">
                <div class="content" >
                    <div class="point1"></div>
                    <div class="point2"></div>
                    <div class="point3"></div>
                </div>
            </div>
        </div>
    </div>
</template>
        
<script>
import Vue from 'vue';
import { ImagePreview } from 'vant';
Vue.use(ImagePreview);
import * as api from "../../api"
import imgMine from '@/assets/imgs/img-mine.jpg'
export default {
    data() {
        return {
            map:{},
            infoWindow:{},
            marker:{},
            imgMine,
            mapList:[],
            id:'',
            showLoading:false,
            showKong:false,
        }
    },
    mounted () {
        this.id = this.$route.query.id ? this.$route.query.id : '202308131610000031800'
        api.getBaseInfoApi(this.id).then(res=>{
            console.log(res.data);
            if(res.data){
                this.showLoading = false
                if(res.data.data.trajectoryList && res.data.data.trajectoryList.length > 0 ){
                    this.showKong = false
                    this.mapList = res.data.data.trajectoryList
                }else{
                    this.showKong = true
                    this.mapList = []
                }
                AMapLoader.load({"key": "935dbc6111fe79e96ae42fcba01d760e","version": "2.0","plugins": []}).then((AMap)=>{
                    this.map = new AMap.Map('container',{
                        center: [119.796624,31.059344],
                        zoom: 5, //地图缩放比例
                        mapStyle: 'amap://styles/whitesmoke', //自定义地图皮肤，用的规划夜皮肤
                        animation: true,
                        viewMode:'3D'//使用3D视图
                    });
                    this.addMarker();
                    let bezierCurvePath = []
                    for(let i = 0; i<this.mapList.length-1; i++){
                        bezierCurvePath.push(
                            [this.mapList[i].position,this.mapList[i+1].position]
                        )
                    }
                    this.bezierCurve(bezierCurvePath, this.map)
                }).catch((e)=>{
                    console.error(e);  //加载错误提示
                });
            }else{
                this.showLoading = true
            }
            
            
        })
   },
   methods: {
       addMarker(){//添加marker标记
            this.mapList.forEach((item,index)=>{
                let position = item.position
                let str=
                `
                    <div class="huibiao">${index+1}</div>
                `
                 
                var text = new AMap.Text({
                    text: '.',
                    anchor: 'center', // 设置文本标记锚点
                    draggable: false,
                    cursor: 'pointer',
                    angle: 0,
                    style: {
                        width: '15px',
                        height: '15px',
                        'border-radius': '50%', //设置为圆形
                        'background-color': '#145CD3', //标记点颜色
                        'border-color': '#145CD3', //标记点边框颜色
                        color: '#145CD3', //文字颜色
                        opacity: '1', //透明度
                        border: '2px solid #FFFFFF',
                        padding:'6px',
                        'box-shadow': '0 2px 4px 0 rgba(20,92,211,0.16)'
                    },
                    position: position, //圆点位置 经纬度
                    label: {content:str,offset: [0,0]},
                })
                text.setMap(this.map);
                text.on('click', (e)=>{
                    let doms  = document.querySelectorAll('.amap-marker-label')
                    doms = [...doms]
                    doms.forEach((items,indexs)=>{
                        if(e.target._y === items){
                            ImagePreview({
                                images: [this.mapList[indexs].photoUrl],
                                closeable: true,
                            });
                        }
                    })
                });
            })
       },
       clickLook(item){
            if(item.photoUrl !== null && item.photoUrl !== ''){
                ImagePreview({
                    images: [item.photoUrl],
                    closeable: true,
                });
            }else{
                console.log("111");
            }
            
       },
       bezierCurve(path, map) {// 贝塞尔曲线：实现带箭头的直线
        for(let i = 0; i<path.length; i++){
          let bezierCurve = new AMap.BezierCurve({
            path: path[i],
            isOutline: false,
            outlineColor: '#ffeeff',
            borderWeight: 1,
            strokeColor: "#3366FF",
            strokeOpacity: 1,
            strokeWeight: 1,
            // 线样式还支持 ''
            strokeStyle: "dashed",
            // strokeStyle是dashed时有效
            strokeDasharray: [5, 5],
            lineJoin: 'round',
            lineCap: 'round',
            zIndex: 50,
            showDir: true
          })
          bezierCurve.setMap(map)
          // 缩放地图到合适的视野级别
          map.setFitView([bezierCurve])
        }
      },
   }
}
</script>
<style lang='less' scoped>
.work-map{
    width: 100%;
    height: 100%;
    #container{
        width: 100%;
        height: 250px;
    }
    .bom-box{
        width: 100%;
        height: calc(100% - 250px);
        position: relative;
        .title-box{
            width: 100%;
            height: 38px;
            background-color: #fff;
            font-size: 15px;
            color: #000;
            line-height: 38px;
            padding-left: 15px;
            letter-spacing: 1.5px;
            
        }
        .bom-list-box{
            width: 100%;
            height: calc(100% - 38px);
            padding: 0px 10px;
            padding-top: 1px;
            padding-bottom: 5px;
            background-color: #fff;
            .bom-list-main{
                width: 100%;
                height: 100%;
                overflow: auto;
                background-color: #fff;
                .bom-lis{
                    width: 100%;
                    // height: 58px;
                    border-bottom: 1px solid #eee;
                    background-color: #fff;
                    color: #000;
                    font-size: 13px;
                    letter-spacing: 0.5px;
                    padding-bottom: 10px;
                    .top-lis-son{
                        width: 100%;
                        height: 35px;
                        display: flex;
                        align-items: center;
                        .lis-s1{
                            width: 40px;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 20px;
                            .img-dianwei{
                                width: 30px;
                                height: 30px;
                            }
                        }
                        .lis-s2{
                            width: 18px;
                            height: 18px;
                            border: 1px solid red;
                            background-color: red;
                            color: #fff;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 20px;
                            font-size: 12px;
                            padding-top: 1px;
                        }
                        .lis-s3{
                            width: calc(100% - 70px);
                            height: 100%;
                            line-height: 40px;
                            font-size: 14px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis; 
                        }
                    }
                    .bom-lis-son{
                        width: 100%;
                        height: 20px;
                        padding-left: 60px;
                    }
                    .img-box-daka{
                        width: 100%;
                        height: 60px;
                        padding-left: 60px;
                        margin-top: 6px;
                        img{
                            width: 70px;
                            height: 60px;
                        }
                    }
                }
                .bom-lis:first-child{
                    border-top: 1px solid #eee;
                }
            }
            .bom-list-main::-webkit-scrollbar{display:none}
            .kong-box{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                img{
                    width: 175px;
                    height: 125px;
                }
                p{
                    font-size: 13px;
                    color: #000;
                    letter-spacing: 1.2px;
                    margin-top: 20px;
                }
            }
        }
        .loadin-box{
            width: 100%;
            height: calc(100% - 38px);
            padding: 0px 10px;
            padding-top: 1px;
            padding-bottom: 5px;
            .content{
                width: 100px;
                height: 50px;
                text-align: center;
                position: absolute;
                top: 0px;
                right: 0;
                left: 0;
                bottom: 0;
                margin: auto;
                line-height: 50px;
                div {
                    width: 8px;
                    height: 8px;
                    background-color: #0055ff;
                    border-radius: 100%;
                    display: inline-block;
                    animation: action 1.5s infinite ease-in-out;
                    animation-fill-mode: both;
                    margin-right: 8px;
                }
                .point1 {
                    animation-delay: -0.3s;
                }
                .point2 {
                    animation-delay: -0.1s;
                }
            }
            @keyframes action {
                0%, 80%, 100% {
                    transform: scale(0);
                }
                40% {
                    transform: scale(1.0);
                }
            }
        }
    }
}
</style>